import React, { useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import {
  fetchCustomerReceiptSettings,
  updateCustomerReceiptSettings,
  updateState,
} from '../../../../redux/customerReceiptSettingsSlice';

import Button from '../../../utilities/button/button';
import PageTitle from '../../../utilities/page-title/page-title';
import CustomerReceiptSettingsForm from './form/customer-receipt-settings-form';
import CustomerReceiptExample from './customer-receipt-settings-example';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import styles from './customer-receipt-settings.module.scss';

function CustomerReceiptSettings() {
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const receiptSettingsStatus = useSelector((state) => state.customerReceiptSettings.status);
  const initialReceiptSettings = useSelector((state) => state.customerReceiptSettings.initialCustomerReceiptSettings);
  const receiptSettings = useSelector((state) => state.customerReceiptSettings.customerReceiptSettings);

  const hasUnsavedChanges = useMemo(
    () => JSON.stringify(receiptSettings) !== JSON.stringify(initialReceiptSettings),
    [initialReceiptSettings, receiptSettings],
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchCustomerReceiptSettings({ token }));
    }
  }, [dispatch, token]);

  const handleFormChange = (id, checked) => {
    const updatedDetails = {
      ...receiptSettings,
      [id]: checked,
    };
    dispatch(updateState(updatedDetails));
  };

  const handleSubmit = () => {
    dispatch(updateCustomerReceiptSettings({ id: receiptSettings.id, updatedDetails: receiptSettings, token }));
  };

  return (
    <>
      <PageTitle name={'Customer Receipt Settings'} />
      {receiptSettingsStatus === 'loading' ? <BackdropLoading /> : null}
      <div className={styles.main_content}>
        <div className={styles.form}>
          <CustomerReceiptSettingsForm settings={receiptSettings} onFormChange={handleFormChange} />
          <Button title="Save" onClick={handleSubmit} disabled={!hasUnsavedChanges} style={{ marginBottom: 0 }} />
        </div>
        <CustomerReceiptExample settings={receiptSettings} />
      </div>
    </>
  );
}

export default CustomerReceiptSettings;
