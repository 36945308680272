import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchReportingEmails,
  createReportingEmail,
  updateExistingReportingEmail,
  removeReportingEmail,
} from "../../../../redux/reportingSlice";
import { addNotification } from "../../../../redux/tooltipSlice";
import {
  fetchLocations,
  updateExistingDailyReportTime,
} from "../../../../redux/locationSlice";

import { useAppContext } from "../../../../context/context";

import PageTitle from "../../../utilities/page-title/page-title";
import Selector from "../../../utilities/selector/selector";
import Button from "../../../utilities/button/button";
import EmailAddressesList from "./reporting-list";

import { IoIosArrowDown } from "react-icons/io";
import styles from "./reporting.module.scss";

const SELECTOR_OPTIONS = [
  { value: "00:30 AM", label: "00:30 AM" },
  { value: "01:00 AM", label: "01:00 AM" },
  { value: "01:30 AM", label: "01:30 AM" },
  { value: "02:00 AM", label: "02:00 AM" },
  { value: "02:30 AM", label: "02:30 AM" },
  { value: "03:00 AM", label: "03:00 AM" },
  { value: "03:30 AM", label: "03:30 AM" },
  { value: "04:00 AM", label: "04:00 AM" },
  { value: "04:30 AM", label: "04:30 AM" },
  { value: "05:00 AM", label: "05:00 AM" },
  { value: "05:30 AM", label: "05:30 AM" },
  { value: "06:00 AM", label: "06:00 AM" },
  { value: "06:30 AM", label: "06:30 AM" },
  { value: "07:00 AM", label: "07:00 AM" },
  { value: "07:30 AM", label: "07:30 AM" },
  { value: "08:00 AM", label: "08:00 AM" },
  { value: "08:30 AM", label: "08:30 AM" },
  { value: "09:00 AM", label: "09:00 AM" },
  { value: "09:30 AM", label: "09:30 AM" },
  { value: "10:00 AM", label: "10:00 AM" },
  { value: "10:30 AM", label: "10:30 AM" },
  { value: "11:00 AM", label: "11:00 AM" },
  { value: "11:15 AM", label: "11:15 AM" },
  { value: "11:30 AM", label: "11:30 AM" },
  { value: "12:00 AM", label: "12:00 AM" },
  { value: "12:30 AM", label: "12:30 AM" },
  { value: "01:00 PM", label: "01:00 PM" },
  { value: "01:30 PM", label: "01:30 PM" },
  { value: "02:00 PM", label: "02:00 PM" },
  { value: "02:30 PM", label: "02:30 PM" },
  { value: "03:00 PM", label: "03:00 PM" },
  { value: "03:30 PM", label: "03:30 PM" },
  { value: "04:00 PM", label: "04:00 PM" },
  { value: "04:30 PM", label: "04:30 PM" },
  { value: "05:00 PM", label: "05:00 PM" },
  { value: "05:30 PM", label: "05:30 PM" },
  { value: "06:00 PM", label: "06:00 PM" },
  { value: "06:30 PM", label: "06:30 PM" },
  { value: "07:00 PM", label: "07:00 PM" },
  { value: "07:30 PM", label: "07:30 PM" },
  { value: "08:00 PM", label: "08:00 PM" },
  { value: "08:30 PM", label: "08:30 PM" },
  { value: "09:00 PM", label: "09:00 PM" },
  { value: "09:30 PM", label: "09:30 PM" },
  { value: "10:00 PM", label: "10:00 PM" },
  { value: "10:30 PM", label: "10:30 PM" },
  { value: "11:00 PM", label: "11:00 PM" },
  { value: "11:30 PM", label: "11:30 PM" },
  { value: "00:00 PM", label: "00:00 PM" },
];

function BusinessReporting() {
  const status = useSelector((state) => state.business_reporting.status);
  const reporting_emails = useSelector(
    (state) => state.business_reporting.reporting_emails
  );
  const locations = useSelector((state) => state.locations.locations);

  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  const [newEmail, setNewEmail] = useState("");
  const [changedEmails, setChangedEmails] = useState({});
  const [selectedReportTime, setSelectedReportTime] = useState("");
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(fetchReportingEmails(token));
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (locations && locations.length > 0) {
      const currentLocation = locations.find(
        (location) => String(location.id) === String(locationId)
      );

      if (currentLocation) {
        setSelectedReportTime(currentLocation.daily_report_sending_time || "");
      }
    }
  }, [locations, locationId]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSelectReportTime = (selectedValue) => {
    setSelectedReportTime(selectedValue);
    setFlag(true);
  };

  const handleAddNewReportingEmail = (selectedValue) => {
    if (!newEmail) {
      dispatch(
        addNotification({
          message: "Email address is empty",
          status: "warning",
        })
      );
      return;
    }

    if (!validateEmail(newEmail)) {
      dispatch(
        addNotification({
          message: "Invalid email address",
          status: "warning",
        })
      );
      return;
    }
    dispatch(createReportingEmail({ email: newEmail, token }));
    setNewEmail("");
  };

  const handleDeleteReportingEmail = (id) => {
    dispatch(removeReportingEmail({ id, token }));
  };

  const handleEmailChange = (id, updatedEmail) => {
    setChangedEmails((prev) => ({
      ...prev,
      [id]: updatedEmail,
    }));
  };

  const handleUpdateReportingEmails = () => {
    Object.keys(changedEmails).forEach((id) => {
      const originalEmailObj = reporting_emails.find(
        (email) => String(email.id) === String(id)
      );

      if (originalEmailObj) {
        const originalEmail = originalEmailObj.email;
        const updatedEmail = changedEmails[id];

        if (originalEmail !== updatedEmail) {
          dispatch(updateExistingReportingEmail({ id, updatedEmail, token }));
          setChangedEmails({});
        } else {
          addNotification({
            message: "Any reporting email wasn`t changed",
            status: "failed",
          });
        }
      }
    });
  };

  const handleUpdateDailyReportTime = () => {
    if (flag) {
      dispatch(
        updateExistingDailyReportTime({
          updatedTime: selectedReportTime,
          token,
          locationId,
        })
      );
      setFlag(false);
    }
  };

  const handleSave = () => {
    handleUpdateReportingEmails();
    handleUpdateDailyReportTime();
  };

  return (
    <div className={styles.business_reporting}>
      <PageTitle name={"Business Reporting"} />
      <div className={styles.reporting_time}>
        <PageTitle
          name={"Send Report At What Time"}
          className={styles.subtitle}
        />
        <div className={styles.selector_wrapper}>
          <label>
            Send Report Time<span>*</span>
          </label>
          <Selector
            icon={IoIosArrowDown}
            items={SELECTOR_OPTIONS}
            placeholder={selectedReportTime}
            onSelectItem={handleSelectReportTime}
            selectedItem={selectedReportTime}
          />
        </div>
      </div>

      <div className={styles.reporting_email}>
        <PageTitle
          name={"Add Email Address For Reporting"}
          className={styles.subtitle}
        />
        <div className={styles.add_email_wrapper}>
          <input
            type="email"
            value={newEmail}
            placeholder="Add Email Address"
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <Button
            className={styles.add_button}
            title={"Add New Email Address"}
            onClick={handleAddNewReportingEmail}
          />
        </div>
        <EmailAddressesList
          emails={reporting_emails}
          onDelete={handleDeleteReportingEmail}
          isLoading={status === "loading"}
          onEmailChange={handleEmailChange}
        />
        <Button
          className={styles.save_button}
          title={"Save"}
          onClick={handleSave}
        />
      </div>
    </div>
  );
}

export default BusinessReporting;
